import { graphql } from 'gatsby'
import _get from 'lodash/get'

export interface KenticoButtonElement extends KontentItem {
  elements: {
    text: KontentTextElement
    target_url: KontentTextElement
  }
}

export class Button {
  text: string
  linkUrl: string

  constructor(rawData: KenticoButtonElement) {
    this.text = _get(rawData, 'elements.text.value', '')
    this.linkUrl = _get(rawData, 'elements.target_url.value', '')
  }
}

export const buttonFragment = graphql`
  fragment kontent_item_button_elementsFragment on kontent_item_button_elements {
    text {
      value
    }
    target_url {
      value
    }
  }
`