import * as React from 'react'
import { RichTextElement } from '@kentico/gatsby-kontent-components'
import { ArrowDirection } from '../../common/typings/enums'
import ArrowButton from '../ArrowButton'
import Person from '../Person'
import { AnimationService } from '../../common/AnimationService'
import { PeopleSection as PeopleSectionClass } from './model'
import { IsMobileContext } from '../../common/Context'
import './style.scss'

const EMPTY_RICH_TEXT = '<p><br></p>'

const PeopleSection: React.FC<PeopleSectionClass> = ({ heading, description, button, employees, backgroundColor }) => {
  const { isMobile } = React.useContext(IsMobileContext)

  const headingRef = React.useRef<HTMLHeadingElement>(null)
  const descriptionRef = React.useRef<HTMLDivElement>(null)
  const personsRefs = React.useRef<Array<HTMLDivElement>>([])
  React.useEffect(() => {
    if (headingRef.current && !AnimationService.isInView(headingRef.current)) {
      AnimationService.slideUp(headingRef.current)
    }
    if (descriptionRef.current && !AnimationService.isInView(descriptionRef.current)) {
      AnimationService.slideUp(descriptionRef.current)
    }
    if (!isMobile) {
      const elementsToAnimate = [...personsRefs.current]
      if (description !== EMPTY_RICH_TEXT) {
        elementsToAnimate.unshift(descriptionRef.current as HTMLDivElement)
      }
      const itemsInLine = 4
      for (let i = 0; i < elementsToAnimate.length; i += itemsInLine) {
        const elementsInLine = elementsToAnimate.slice(i, i + itemsInLine)
        AnimationService.slideSequenceUp(elementsInLine)
      }
    }
  }, [description, isMobile])

  return (
    <section className="PeopleSection GlobSectPadSpacing px-mobile-xs lg:px-m" style={{ backgroundColor: backgroundColor }}>
      <div className="SpacingAdjust pt-mobile-l lg:pt-l"></div>
      <div className="grid grid-cols-12 gap-x-m">
        {heading && (
          <h2 ref={headingRef} className="FontL col-span-12 mb-mobile-s lg:mb-s">
            {heading}
          </h2>
        )}
        <section className="grid grid-cols-12 gap-x-m col-span-12 overflow-hidden">
          {description !== EMPTY_RICH_TEXT && (
            <article className="FontM col-span-12 mb-mobile-l lg:col-span-3 lg:mb-m mt-mobile-m lg:mt-m" ref={descriptionRef}>
              <div className="block mb-mobile-s lg:mb-s">
                <RichTextElement value={description} />
              </div>
              {button.text && button.linkUrl && (
                <ArrowButton
                  title={button.text}
                  url={button.linkUrl}
                  direction={ArrowDirection.RIGHT}
                />
              )}
            </article>
          )}
          {employees &&
            employees.map((person, index) => {
              return (
                <Person 
                  {...person} 
                  key={index} 
                  animate={isMobile}
                  className="mt-mobile-m lg:mt-m"
                  ref={(el: HTMLDivElement) => personsRefs.current[index] = el}
                />
              )
            })
          }
        </section>
      </div>
      {backgroundColor && <div className="pt-mobile-m lg:pt-m" style={{ backgroundColor: backgroundColor }}></div>}
    </section>
  )
}

export default PeopleSection
