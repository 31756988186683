import * as React from 'react'
import { graphql, PageProps } from 'gatsby'
import { IsMobileContext, LanguageContext } from '../common/Context'

// components
import Layout from '../components/Layout'
import Navbar from '../components/Navbar'
import HeroSection from '../components/HeroSection'
import PeopleSection from '../components/PeopleSection'
import AccordionSection from '../components/AccordionSection'
import InNumbers from '../components/InNumbersSection'
import ClientsSection from '../components/ClientsSection'
import ImageVideoSection from '../components/ImageVideoSection'
import Footer from '../components/Footer'
import FloatingButton from '../components/FloatingButton'

// hooks, models
import { useSeoData } from '../components/SeoComponent/model'
import { useHeroSectionData, HeroSection as HeroSectionClass } from '../components/HeroSection/model'
import { usePeopleSectionData, PeopleSection as PeopleSectionClass } from '../components/PeopleSection/model'
import { useAccordionSectionData, AccordionSection as AccordionSectionClass } from '../components/AccordionSection/model'
import { useInNumbersSectionData, InNumbersSection } from '../components/InNumbersSection/model'
import { useClientsSectionData, ClientsSection as ClientsSectionClass } from '../components/ClientsSection/model'
import { useImageVideoSectionData, ImageVideoSection as ImageVideoSectionClass } from '../components/ImageVideoSection/model'
import { useLinksGridData, LinksGrid } from '../components/LinksGrid/model'
import { useNavbarData, Navbar as NavbarClass } from '../components/Navbar/model'

import withSSR from '../hoc/withSSR'
import './team.scss'

const TeamPage: React.FC<PageProps> = ({ location: { pathname } }) => {
  const { language: initialLanguage } = React.useContext(LanguageContext)
  const [language, setLanguage] = React.useState(initialLanguage)

  /*  For data collecting hooks below (e.g. useLinksGridData), 
  You need to know page codename from kentico and optionally section codename.
  Data service is searching query for this elements and collects the right data to components  */
  const pageCodename = 'team_page'
  const seoData = useSeoData(language, pageCodename, pathname)
  const pageData = {
    imageVideoSectionData: useImageVideoSectionData(language, pageCodename),
    heroSectionData: useHeroSectionData(language, pageCodename),
    peopleSectionData: usePeopleSectionData(language, pageCodename),
    accordionSectionData: useAccordionSectionData(language, pageCodename),
    inNumbersData: useInNumbersSectionData(language, pageCodename),
    clientsSectionData: useClientsSectionData(language, pageCodename),
    linksGridData: useLinksGridData(language, pageCodename),
    navbarData: useNavbarData(language, pageCodename, pathname),
  }

  return (
    <Layout seoData={seoData} language={language} setLanguage={setLanguage}>
      <TeamPageTemplateWithSSR pageData={pageData} />
    </Layout>
  )
}

interface TeamPageProps {
  isMobile: boolean
  pageData: {
    imageVideoSectionData: ImageVideoSectionClass
    heroSectionData: HeroSectionClass
    peopleSectionData: PeopleSectionClass
    accordionSectionData: AccordionSectionClass | null
    inNumbersData: InNumbersSection
    clientsSectionData: ClientsSectionClass
    linksGridData: LinksGrid
    navbarData: NavbarClass
  }
}

const TeamPageTemplate: React.FC<TeamPageProps> = ({
  isMobile,
  pageData: {
    imageVideoSectionData,
    heroSectionData,
    peopleSectionData,
    accordionSectionData,
    inNumbersData,
    clientsSectionData,
    linksGridData,
    navbarData,
  }
}) => {

  return (
    <IsMobileContext.Provider value={{ isMobile }}>
      <header>
        <Navbar {...linksGridData} {...navbarData} />
      </header>
      <main className="Team">
        <HeroSection {...heroSectionData} headingColumns={{desktop: 6, mobile: 12}} descriptionColumns={{desktop: 6, mobile: 12}} />
        <PeopleSection {...peopleSectionData} />
        {accordionSectionData && <AccordionSection {...accordionSectionData} />}
        <InNumbers {...inNumbersData} />
        <ClientsSection {...clientsSectionData} />
        <ImageVideoSection {...imageVideoSectionData} />
      </main>
      <footer>
        <Footer {...linksGridData} />
      </footer>
      <FloatingButton />
    </IsMobileContext.Provider>
  )
}
const TeamPageTemplateWithSSR = withSSR<TeamPageProps>(TeamPageTemplate)

export default TeamPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common","team"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
