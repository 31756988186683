import { graphql, useStaticQuery } from 'gatsby'
import { filterPageDataByLanguage } from '../../common/serviceHelpers'
import _get from 'lodash/get'
import { KenticoEmployeeElement, Employee } from '../Person/model'
import { Button, KenticoButtonElement } from '../ArrowButton/model'

/* Kentico model: People Section*/
export interface KenticoPeopleSection extends KontentItem {
  heading: KontentTextElement
  description: KontentRichTextElement
  button: KenticoButtonElement
  background_color: KontentTextElement
  employees: KenticoEmployeeElement[]
}

export class PeopleSection {
  heading: string
  description: string
  button: Button
  backgroundColor: string
  employees: Employee[]

  constructor(data: KenticoPeopleSection) {
    this.heading = _get(data, 'heading.value', '')
    this.description = _get(data, 'description.value', '')
    this.button = new Button(_get(data, 'button.value[0]', {}))
    this.backgroundColor = _get(data, 'background_color.value', '')
    this.employees = _get(data, 'people.value', [])
      .filter(Boolean)
      .map((element: KenticoEmployeeElement) => {
        return new Employee(element)
      })
  }
}

export const peopleSectionFragment = graphql`
  query PeopleSectionQuery {
    allKontentItemPage {
      nodes {
        elements {
          content {
            value {
              ... on kontent_item_people_section {
                elements {
                  ...kontent_item_people_section_elementsFragment
                }
                preferred_language
                system {
                  codename
                }
              }
            }
          }
        }
        system {
          codename
        }
      }
    }
  }
`

export const peopleSectionElementsFragment = graphql`
  fragment kontent_item_people_section_elementsFragment on kontent_item_people_section_elements {
    heading {
      value
    }
    description {
      value
    }
    people {
      value {
        ... on kontent_item_employee_element {
          elements {
            ...kontent_item_employee_element_elementsFragment
          }
        }
      }
    }
    button {
      value {
        ... on kontent_item_button {
          elements {
            ...kontent_item_button_elementsFragment
          }
        }
      }
    }
    background_color {
      value
    }
  }
`

export const usePeopleSectionData = (language: string, pageCodename: string, sectionCodename = '') => {
  const data = useStaticQuery(peopleSectionFragment)
  const peopleSectionData: KenticoPeopleSection = filterPageDataByLanguage(data.allKontentItemPage, pageCodename, sectionCodename, language)
  return new PeopleSection(peopleSectionData)
}